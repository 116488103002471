import React from 'react'
import InstagramFeed from './InstagramFeed'
import './Footer.css'

export default () => (
  <div>
    <div class="callus">
      <div class="container">
        <p><strong>Call us</strong> for great customer care at <strong><a href="tel:15204770741">(520)477-0741</a></strong> to schedule your best spa repair experience ever.</p>
        <p><em>“Rodeo Spas and Hot Tub Repair” wants to be your Repair Professional.</em></p>
      </div>
    </div>
    <footer className="footer">

      <div className="container taCenter">
          © Copyright {new Date().getFullYear()} All rights reserved. Rodeo Spas LLC.
      </div>
      <div  className="container taCenter">
          <small>Website designed by <a href="https://www.allthingswww.com">All Things WWW</a></small>
      </div>
    </footer>
  </div>
)
