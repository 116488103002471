import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from "gatsby"
import styled from 'styled-components';

import Image from './Image'
import Content from './Content'
import './PageHeader.css'
import BackgroundImage from 'gatsby-background-image'


const BlackBackground = styled.div`
  background-color: var(--darkGrey);
`

const PageHeader = ({
  title,
  subtitle,
  backgroundImage,
  large,
  className = ''
}) => {
  if (large) className += ' PageHeader-large'
  const render = <div className={`PageHeader relative ${className}`}>
    <div className="container relative">
      <h1 className="PageHeader--Title">{title}</h1>
      {subtitle && (
        <Content className="PageHeader--Subtitle" src={subtitle} />
      )}
    </div>
  </div>



  return backgroundImage ?
    <StaticQuery
      query={graphql`query {
                file(relativePath: {eq: "hottub2.png"}){
                  id
                  name
                  childImageSharp {
                    # Specify the image processing specifications right in the query.
                    # Makes it trivial to update as your page's design changes.
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }`}
      render={data => <BackgroundImage fluid={data.file.childImageSharp.fluid} >{render}</BackgroundImage>
      } />
    : <BlackBackground>{render}</BlackBackground>




}

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default PageHeader
